/* Mobile */

.rating{
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 5px;
    text-align: left;
}

.rating-star,
.rating-star-blank{
    height: 20px;
    width: 20px;
}

.rating-star-blank{
    color: #FFFFFF;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
@media screen and (min-width: 1024px){

    .rating-star,
    .rating-star-blank{
        height: 24px;
        width: 24px;
    }


}

/* Laptops */
@media screen and (min-width: 1280px){

    .rating-star,
    .rating-star-blank{
        height: 20px;
        width: 20px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .rating-star,
    .rating-star-blank{
        height: 24px;
        width: 24px;
    }

}