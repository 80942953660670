/* Mobile */

.reviews{
    text-align: center;
    width: 100%;
    padding: var(--content-padding);
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .reviews{
        position: absolute;
        height: 100%;
        width: 100%;
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */