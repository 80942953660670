/* Mobile */

.input-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-error-label{
    color: #742E2E;
}

.invalid-input{
    border-color: #742E2E;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */