/* No Data Container */
.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh; /* Adjust for visual balance */
    text-align: center;
}

/* Sad Icon Styling with Animation */
.no-data-icon {
    color: #676767;
    height: 75px;
    width: 75px;
}

/* Text Styling */
.no-data-text {
    color: #676767;
}

/* Responsive Adjustments */
@media screen and (min-width: 640px) {

    .no-data-icon {
        height: 75px;
        width: 75px;
    }

}

@media screen and (min-width: 768px) {
    .no-data-icon {
        height: 75px;
        width: 75px;
    }
}
