/* Mobile */

.brand-tile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: calc(100% / 2 - 5px);
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    margin: 0;
}

.brand-image{
    border-radius: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.brand-name{
    line-height: 0;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .brand-tile{
        width: calc(100% / 3 - 10px);
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .brand-tile{
        width: calc(100% / 3 - 10px);
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .brand-tile{
        width: calc(100% / 4 - 10px);
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .brand-tile{
        width: calc(100% / 4 - 10px);
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .brand-tile{
        width: calc(100% / 5 - 10px);
    }

}