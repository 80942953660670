/* Mobile */

.menu{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 80%;
    background-color: var(--secondary-color);
    transform: translateX(-100%);
    animation: slideIn 0.5s ease-in-out forwards 0.5s;
}

.menu-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--global-padding);
    padding-left: 0;
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.1);
}

.menu-logo{
    height: 55px;
    width: auto;
}

.menu-toggle{
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.menu-content{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    height: 100%;
    padding: var(--global-padding);
    padding-top: 20px;
}

.menu-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    font-size: 18px;
    color: rgba(255,255,255,0.5)
}

.link-icon{
    height: 24px;
    width: 24px;
}

.menu-footer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    border-top: 0.1px solid rgba(255, 255, 255, 0.1);
    padding: var(--global-padding);
}

.lang-selector{
    height: 32px;
    width: auto;
    cursor: pointer;
}
/* Small Tablets */
@media screen and (min-width: 640px){

    .menu{
        width: 350px;
    }

    .menu-logo{
        height: 60px;
        width: auto;
    }

    .menu-toggle{
        height: 28px;
        width: 28px;
        cursor: pointer;
    }

    .menu-link{
        font-size: 20px;
    }

    .link-icon{
        height: 28px;
        width: 28px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .menu{
        width: 350px;
    }

    .menu-logo{
        height: 65px;
        width: auto;
    }

    .menu-toggle{
        height: 28px;
        width: 28px;
        cursor: pointer;
    }

    .menu-content{
        row-gap: 10px;
    }

    .menu-link{
        font-size: 20px;
    }

    .link-icon{
        height: 28px;
        width: 28px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .menu{
        width: 400px;
    }

    .menu-logo{
        height: 70px;
        width: auto;
    }

    .menu-toggle{
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .menu-content{
        row-gap: 20px;
    }

    .menu-link{
        font-size: 24px;
    }

    .link-icon{
        height: 32px;
        width: 32px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .menu{
        width: 350px;
    }

    .menu-logo{
        height: 65px;
        width: auto;
    }

    .menu-toggle{
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .menu-content{
        row-gap: 10px;
    }

    .menu-link{
        font-size: 18px;
    }

    .link-icon{
        height: 28px;
        width: 28px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .menu{
        width: 400px;
    }

    .menu-logo{
        height: 75px;
        width: auto;
    }

    .menu-toggle{
        height: 36px;
        width: 36px;
        cursor: pointer;
    }

    .menu-content{
        row-gap: 20px;
    }

    .menu-link{
        font-size: 20px;
    }

    .link-icon{
        height: 32px;
        width: 32px;
    }

}

@keyframes slideIn {
    0%{
        transform: translateX(-100%);
    }

    100%{
        transform: translateX(0%);
    }
}