/* Mobile */

.review-form{
    text-align: center;
    background-color: var(--primary-color);
    padding: var(--global-padding);
    padding-top: 40px;
    padding-bottom: 30px;
    border-radius: var(--button-border-radius);
    height: auto;
    max-height: 80vh;
    width: 90vw;
    overflow-y: auto;
}

.review-form-image{
    height: 250px;
    width: 250px;
    margin-bottom: 25px;
    object-fit: cover;
    border-radius: 10px;
}

.review-form-controller{
    display: flex;
    column-gap: 10px;
}

.rating{
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 5px;
    text-align: left;
    margin-bottom: 20px;
}

.rating-star{
    height: 20px;
    width: 20px;
}

.review-form-loader{
    height: 100%;
}

.review-form-error{
    height: 100%;
}

.review-form-success{
    height: 100%;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .review-form{
        padding: var(--global-padding);
        max-height: 65vh;
        width: 450px;
    }
}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .review-form{
        padding: var(--global-padding);
        padding-top: 40px;
        padding-bottom: 30px;
        max-height: 70vh;
        width: 450px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .review-form{
        padding: var(--content-padding);
        padding-top: 60px;
        padding-bottom: 40px;
        max-height: 60vh;
        width: 550px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .review-form{
        padding: var(--content-padding);
        padding-top: 30px;
        padding-bottom: 40px;
        max-height: 80vh;
        width: 450px;
    }
}

/* Large Screens */
@media screen and (min-width: 1536px){

    .review-form{
        padding: var(--content-padding);
        padding-top: 40px;
        padding-bottom: 40px;
        max-height: 80vh;
        width: 550px;
    }

}