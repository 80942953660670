/* Mobile */

.product-display{
    text-align: center;
}

.product-name{
    color: #676767;
    text-align: center;
}

.product-preview{
    height: auto;
    width: 80vw;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .product-preview{
        height: auto;
        width: 60vw;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .product-preview{
        height: auto;
        width: 60vw;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .product-preview{
        height: auto;
        width: 60vw;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .product-display{
        text-align: center;
        padding: var(--global-padding);
    }

    .product-preview{
        height: 65vh;
        width: auto;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .product-preview{
        height: 65vh;
        width: auto;
    }

}