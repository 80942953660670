/* Mobile */

.product-catalog{
    padding-top: 10px;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .product-catalog{
        display: grid;
        grid-template-columns: 300px calc(100% - 300px);
        grid-gap: 20px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .product-catalog{
        grid-template-columns: 400px calc(100% - 400px);
        grid-gap: 20px;
    }

}