/* Mobile */

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
@media screen and (min-width: 1024px){

    .contact-us-form{
        width: 80%;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .contact-us-form{
        width: 50%;
    }

}

/* Large Screens */
/*@media screen and (min-width: 1536px){}*/