/* Mobile */

.our-brands{
    margin-top: 40px;
}

.brands-gallery{
    margin-top: 40px;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    grid-gap: 10px;
    align-items: center;
}


/* Small Tablets */
@media screen and (min-width: 640px){

    .our-brands{
        margin-top: 50px;
    }

    .brands-gallery{
        margin-top: 50px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .our-brands{
        margin-top: 50px;
    }

    .brands-gallery{
        margin-top: 50px;
    }
}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .our-brands{
        margin-top: 50px;
    }

    .brands-gallery{
        margin-top: 50px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .our-brands{
        margin-top: 50px;
    }

    .brands-gallery{
        margin-top: 50px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .our-brands{
        margin-top: 100px;
    }

    .brands-gallery{
        margin-top: 50px;
    }

}