/* Mobile */

.product-filter{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    width: 100%;
}

.filter-title{
    display: none;
}

.sort-by, .brand{
    display: none;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .product-filter{
        flex-direction: column;
    }


    .filter-title,
    .sort-by,
    .brand{
        display: block;
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */