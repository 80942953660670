/* Mobile */

.carousel-display {
    position: relative;
    height: 250px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-display img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-controller{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    margin-top: 15px;
}

.carousel-selector{
    height: 10px;
    width: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.35);
    border-radius: 100%;
    transition: 0.5s;
}

.selector-active{
    background-color: #FFFFFF;
    transition: 0.5s;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .carousel-display {
        height: 300px;
    }

    .carousel-controller{
        margin-top: 25px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .carousel-display {
        height: 300px;
    }

    .carousel-controller{
        margin-top: 25px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .carousel-display {
        height: 350px;
    }

    .carousel-controller{
        margin-top: 25px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .carousel-display {
        height: 400px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .carousel-display {
        height: 450px;
    }

}