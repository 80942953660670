/* Mobile */

.about-us{
    margin-top: 40px;
}

.qualities{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 35px;
    margin-top: 35px;
}

.visualizer{
    margin-top: 25px;
}

.about-us-img{
    height: auto;
    width: 100%;
    border-radius: 10px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .about-us{
        margin-top: 50px;
    }

    .qualities{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 50px;
        margin-top: 35px;
    }

    .visualizer{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
    }

    .about-us-img{
        height: 500px;
        width: 500px;
        border-radius: 10px;
        object-fit: cover;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .about-us{
        margin-top: 50px;
    }

    .qualities{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 65px;
        margin-top: 35px;
    }

    .visualizer{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .about-us-img{
        height: 600px;
        width: 600px;
        border-radius: 10px;
        object-fit: cover;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .about-us{
        margin-top: 50px;
    }

    .qualities{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 65px;
        margin-top: 35px;
    }

    .visualizer{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }

    .about-us-img{
        height: 600px;
        width: 600px;
        border-radius: 10px;
        object-fit: cover;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .about-us{
        display: grid;
        grid-template-columns: 60% 40%;
        margin-top: 50px;
    }

    .visualizer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-us-img{
        height: 400px;
        width: 400px;
        object-fit: cover;
        border-radius: 10px;
    }

    .qualities{
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 48px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .about-us{
        display: grid;
        grid-template-columns: 60% 40%;
        margin-top: 100px;
    }

    .visualizer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-us-img{
        height: 500px;
        width: 500px;
        object-fit: cover;
        border-radius: 10px;
    }

    .qualities{
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 48px;
    }

}