/* Mobile */

.navbar{
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--global-padding);
    background-color: var(--primary-color);
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    z-index: 1;
}

.navbar-icon{
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.navbar-logo{
    height: 55px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .navbar-icon{
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .navbar-logo{
        height: 60px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .navbar-icon{
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .navbar-logo{
        height: 65px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .navbar-icon{
        height: 36px;
        width: 36px;
        cursor: pointer;
    }

    .navbar-logo{
        height: 70px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .navbar-icon{
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .navbar-logo{
        height: 65px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .navbar-icon{
        height: 36px;
        width: 36px;
        cursor: pointer;
    }

    .navbar-logo{
        height: 75px;
    }

}