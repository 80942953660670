/* Mobile */

.brands{
    width: 100%;
    padding: var(--content-padding);
}

.all-products-btn{
    width: 100%;
}

.brands-gallery{
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    padding-bottom: 40px;
}


/* Small Tablets */
@media screen and (min-width: 640px){

    .all-products-btn{
        width: 300px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .all-products-btn{
        width: 300px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .all-products-btn{
        width: 400px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .brands{
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .all-products-btn{
        width: 300px;
    }
}

/* Large Screens */
@media screen and (min-width: 1536px){

    .all-products-btn{
        width: 350px;
    }

}