/* Mobile */

.quotation-form{
    text-align: center;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .quotation-form{
        text-align: center;
        height: 100%;
        padding: var(--global-padding);
        overflow-y: auto;
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */