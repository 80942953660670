/* Mobile */

.brand-profile{
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
}

.brand-banner{
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.brand-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.brand-picture{
    height: 200px;
    width: 200px;
    border-radius: 100%;
    object-fit: cover;
    margin-top: -100px;
}

/*.brand-info{*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: flex-start;*/
/*    column-gap: 50px;*/
/*    padding: 0 0 0 50px;*/
/*}*/

/* Small Tablets */
@media screen and (min-width: 640px){

    .brand-banner{
        height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .brand-picture{
        height: 300px;
        width: 300px;
        border-radius: 100%;
        object-fit: cover;
        margin-top: -150px;
    }


}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .brand-banner{
        height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .brand-picture{
        height: 300px;
        width: 300px;
        border-radius: 100%;
        object-fit: cover;
        margin-top: -150px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .brand-banner{
        height: 400px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .brand-picture{
        height: 350px;
        width: 350px;
        border-radius: 100%;
        object-fit: cover;
        margin-top: -175px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .brand-profile{
        padding-bottom: 25px;
    }

    .brand-info{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 50px;
        padding: 10px 0 0 50px;
        text-align: left;
    }

    .brand-banner{
        height: 350px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .brand-picture{
        height: 250px;
        width: 250px;
        border-radius: 100%;
        object-fit: cover;
        margin-top: -135px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .brand-profile{
        padding-bottom: 35px;
    }

    .brand-info{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 50px;
        padding: 20px 0 0 50px;
        text-align: left;
    }

    .brand-banner{
        height: 400px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .brand-picture{
        height: 300px;
        width: 300px;
        border-radius: 100%;
        object-fit: cover;
        margin-top: -170px;
    }


}