/* Mobile */

.reviews-controller{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.review-filter{
    width: 100%;
}

.create-review-button{
    width: 100%;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .review-filter{
        width: 250px;
    }

    .create-review-button{
        width: 250px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

        .review-filter{
            width: 300px;
        }

        .create-review-button{
            width: 300px;
        }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

        .review-filter{
            width: 350px;
        }

        .create-review-button{
            width: 350px;
        }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .review-filter{
        width: 300px;
    }

    .create-review-button{
        width: 300px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .review-filter{
        width: 350px;
    }

    .create-review-button{
        width: 350px;
    }

}