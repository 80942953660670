/* Mobile */

.reviews-gallery{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    padding-bottom: 25px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .reviews-gallery{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

}

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */