/* Mobile */

.product-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.5s;
    text-align: center;
}

.product-image{
    height: auto;
    border-radius: 10px 10px 0 0;
    width: 100%;
}

.product-card-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--global-padding);
}

.product-prev-price{
    text-decoration: line-through;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .product-card{
        width: calc(100% / 2 - 5px)
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .product-card{
        width: calc(100% / 3 - 7px)
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .product-card{
        width: calc(100% / 3 - 7px)
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .product-card{
        width: calc(100% / 3 - 7px)
    }

    .product-card:hover{
        border: 1px solid #FFFFFF;
        transition: 0.5s;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .product-card{
        width: calc(100% / 4 - 8px)
    }

}