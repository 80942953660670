/* Mobile */

.product-gallery{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-gap: 10px;
    width: 100%;
    margin-top: 10px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .product-gallery{
        flex-direction: row;
    }

}

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
@media screen and (min-width: 1280px){

    .product-gallery{
        flex-direction: row;
        width: calc(100% - 20px);
    }

}

/* Large Screens */
/* @media screen and (min-width: 1536px){} */