/* Mobile */

.product-specs{
    width: 100%;
    border-collapse: collapse;
    line-height: 40px;
    margin-top: 20px;
}

.product-specs tr:nth-child(odd) {
    background-color: var(--secondary-color);
}

.product-specs td{
    padding-left: 10px;
}

.spec-title{
    white-space: nowrap;
    width: 1px;
    padding-right: 10px;
}

.spec-value {
    width: 100%;
    font-weight: bolder;
}

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */