/* Mobile */

.review-card{
    background-color: var(--secondary-color);
    border-radius: var(--button-border-radius);
    padding: var(--global-padding);
    padding-top: 20px;
    padding-bottom: 20px;
    height: 260px;
    width: 100%;
    text-align: left;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.review-card small {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .review-card{
        height: 275px;
        width: calc(100% / 2 - 5px);
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .review-card{
        height: 275px;
        width: calc(100% / 2 - 5px);
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .review-card{
        height: 300px;
        width: calc(100% / 2 - 5px);
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .review-card{
        height: 285px;
        width: calc(100% / 3 - 7px);
        cursor: pointer;
        transition: 0.5s;
    }

    .review-card:hover{
        background-color: #3f3f3f;
        transition: 0.5s;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .review-card{
        height: 300px;
        width: calc(100% / 4 - 8px);
    }

}