/* Mobile */

.inspiration{
    text-align: center;
    margin-top: 40px;
}

.inspo-video{
    height: 200px;
    width: 100%;
    margin-top: 25px;
    border-radius: 10px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .inspiration{
        margin-top: 50px;
    }

    .inspo-video{
        height: 400px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .inspiration{
        margin-top: 50px;
    }

    .inspo-video{
        height: 400px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .inspiration{
        margin-top: 50px;
    }

    .inspo-video{
        height: 500px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .inspiration{
        margin-top: 50px;
        padding-left: 200px;
        padding-right: 200px;
    }

    .inspo-video{
        height: 500px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .inspiration{
        margin-top: 100px;
        padding-left: 300px;
        padding-right: 300px;
    }

    .inspo-video{
        height: 600px;
    }

}