/* Mobile */

.faq{
    width: 100%;
    cursor: pointer;
}

.faq:not(:last-child){
    border-bottom: 0.1px solid rgba(255, 255, 255, 0.1);
}

.faq-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question{
    text-align: left;
    font-size: 16px;
}

.faq-answer{
    text-align: left;
    color: #676767;
    margin-top: 0;
}

.faq-arrow{
    color: #FFFFFF;
    display: none;
}

.faq-arrow-flip{
    transform: rotate(180deg);
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .faq{
        width: 550px;
    }

    .faq-question{
        font-size: 18px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .faq{
        width: 600px;
    }

    .faq-question{
        font-size: 18px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .faq{
        width: 750px;
    }

    .faq-question{
        font-size: 20px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

        .faq{
            width: 800px;
        }

        .faq-question{
            font-size: 16px;
        }

        .faq-arrow{
            display: block;
            height: 28px;
            width: 28px;
        }

}

/* Large Screens */
 @media screen and (min-width: 1536px){

        .faq{
            width: 900px;
        }

        .faq-question{
            font-size: 18px;
        }

        .faq-arrow{
            display: block;
            height: 32px;
            width: 32px;
        }

 }