/* Global Variables */
:root{
    /***************************************** Pages Styling ****************************************/
    --global-padding: 0 20px;
    --content-padding: 75px 20px;

    /****************************************** Colouring  *******************************************/
    --primary-color: #141414;
    --secondary-color: #1F1F1F;
    --primary-title-color: #3f3f3f;
    --text-color: #FFFFFF;
    --placeholder-color: #5A5A5A;
    --success-text-color: #017201;
    --invalid-text-color: #742E2E;

    /****************************************** Text Styling ****************************************/
    /*Content Styles*/
    --text-margin: 15px 0;

    /* Fonts Styles */
    --font-family: "Poppins", sans-serif;
    --font-weight: 300;
    --font-style: normal;

     /* Fonts Sizing */
    --primary-title-font-size: 40px;
    --secondary-title-font-size: 20px;
    --content-font-size: 14px;
    --content-line-height: 30px;

    /**************************************** Inputs Styling ****************************************/
    /* Inputs Sizing */
    --input-height: 45px;
    --input-width: 100%;
    --input-padding: 10px;
    --input-margin: 5px 0;
    --input-border-radius: 10px;
    --textarea-height: 120px;

    /**************************************** Button Styling ****************************************/
    /* Button Sizing */
    --button-height: 45px;
    --button-width: 100%;
    --button-padding: 10px;
    --button-border-radius: 10px;
}

/* Mobile */

*{
  box-sizing: border-box;
  outline: none;
  user-select: none;
}

html, body{
  margin: 0;
  padding: 0;
  background-color: var(--primary-color);
}

h1, h2, p, a, label, small,
input, textarea, button, table
{
    font-family: var(--font-family), sans-serif;
    font-weight: var(--font-weight);
    font-style: var(--font-style);
    color: var(--text-color);
    margin: var(--text-margin);
}

h1{
  font-size: var(--primary-title-font-size);
  color: var(--primary-title-color);
}

h2{
  font-size: var(--secondary-title-font-size);
  color: var(--text-color);
}

p{
  font-size: var(--content-font-size);
  color: var(--text-color);
  text-align: justify;
  line-height: var(--content-line-height);
}

small{
    color: #676767;
}

a{
    color: var(--text-color);
    text-decoration: none;
    transition: 0.5s;
}

a:hover{
    color: var(--text-color);
    transition: 0.5s;
}

a.active{
    color: var(--text-color);
    text-decoration: underline;
    transition: 0.5s;
}

label{
  font-size: var(--content-font-size);
  color: var(--text-color);
}

input, select, textarea{
    background-color: var(--secondary-color);
    font-size: var(--content-font-size);
    padding: var(--input-padding);
    margin: var(--input-margin);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: var(--input-border-radius);
    color: var(--text-color);
}

input, select{
  height: var(--input-height);
  width: var(--input-width);
}

textarea{
  height: var(--textarea-height);
  width: var(--input-width);
  resize: none;
}

input::placeholder,
select::placeholder,
textarea::placeholder{
  color: var(--placeholder-color);
}

button{
    background-color: transparent;
    color: var(--text-color);
    font-size: var(--content-font-size);
    height: var(--button-height);
    width: var(--button-width);
    padding: var(--button-padding);
    border-radius: var(--button-border-radius);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.5s;
}

button:hover{
  cursor: pointer;
  border: 1px solid #FFFFFF;
  transition: 0.5s;
}

button:disabled{
    color: rgba(255, 255, 255, 0.35);
    cursor: not-allowed;
}

button:disabled:hover{
    border: 1px solid rgba(255, 255, 255, 0.1);
}


/* Small Tablets */
@media screen and (min-width: 640px){

    :root{
        /***************************************** Pages Styling ****************************************/
        --global-padding: 0 25px;
        --content-padding: 90px 25px;

        /****************************************** Text Styling ****************************************/
        /* Fonts Sizing */
        --secondary-title-font-size: 24px;
        --primary-title-font-size: 40px;
        --content-font-size: 16px;
        --content-line-height: 35px;

        /**************************************** Inputs Styling ****************************************/
        /* Inputs Sizing */
        --input-height: 50px;

        /**************************************** Button Styling ****************************************/
        /* Button Sizing */
        --button-height: 50px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    :root{
        /***************************************** Pages Styling ****************************************/
        --global-padding: 0 25px;
        --content-padding: 90px 25px;

        /****************************************** Text Styling ****************************************/
        /* Fonts Sizing */
        --primary-title-font-size: 60px;
        --secondary-title-font-size: 28px;
        --content-font-size: 16px;
        --content-line-height: 35px;

        /**************************************** Inputs Styling ****************************************/
        /* Inputs Sizing */
        --input-height: 50px;

        /**************************************** Button Styling ****************************************/
        /* Button Sizing */
        --button-height: 50px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    :root{
        /***************************************** Pages Styling ****************************************/
        --global-padding: 0 25px;
        --content-padding: 115px 25px;

        /****************************************** Text Styling ****************************************/
        /* Fonts Sizing */
        --primary-title-font-size: 60px;
        --secondary-title-font-size: 32px;
        --content-font-size: 18px;
        --content-line-height: 45px;

        /**************************************** Inputs Styling ****************************************/
        /* Inputs Sizing */
        --input-height: 50px;

        /**************************************** Button Styling ****************************************/
        /* Button Sizing */
        --button-height: 50px;
    }
}

/* Laptops */
@media screen and (min-width: 1280px){

    :root{
        /***************************************** Pages Styling ****************************************/
        --global-padding: 0 25px;
        --content-padding: 90px 25px;

        /****************************************** Text Styling ****************************************/
        /* Fonts Sizing */
        --primary-title-font-size: 60px;
        --secondary-title-font-size: 24px;
        --content-font-size: 14px;
        --content-line-height: 40px;

        /**************************************** Inputs Styling ****************************************/
        /* Inputs Sizing */
        --input-height: 50px;

        /**************************************** Button Styling ****************************************/
        /* Button Sizing */
        --button-height: 45px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    :root{
        /***************************************** Pages Styling ****************************************/
        --global-padding: 0 25px;
        --content-padding: 100px 25px;

        /****************************************** Text Styling ****************************************/
        /* Fonts Sizing */
        --primary-title-font-size: 60px;
        --secondary-title-font-size: 28px;
        --content-font-size: 18px;
        --content-line-height: 45px;

        /**************************************** Inputs Styling ****************************************/
        /* Inputs Sizing */
        --input-height: 50px;

        /**************************************** Button Styling ****************************************/
        /* Button Sizing */
        --button-height: 55px;
    }

}

/* Mobile */

/* Small Tablets */
/*@media screen and (min-width: 640px){}*/

/* Medium Tablets */
/* @media screen and (min-width: 768px){} */

/* Large Tablets */
/* @media screen and (min-width: 1024px){} */

/* Laptops */
/*@media screen and (min-width: 1280px){}*/

/* Large Screens */
/* @media screen and (min-width: 1536px){} */