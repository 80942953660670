/* Mobile */

.review-user{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}

.user-pp{
    color: #FFFFFF;
    height: 30px;
    width: 30px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .user-pp{
        color: #FFFFFF;
        height: 36px;
        width: 36px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .user-pp{
        color: #FFFFFF;
        height: 36px;
        width: 36px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .user-pp{
        color: #FFFFFF;
        height: 40px;
        width: 40px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .user-pp{
        color: #FFFFFF;
        height: 36px;
        width: 36px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .user-pp{
        color: #FFFFFF;
        height: 40px;
        width: 40px;
    }

}