/* Mobile */

.product-detail{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.product-image{
    height: auto;
    width: 100%;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .product-image{
        height: auto;
        width: 500px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .product-image{
        height: auto;
        width: 500px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .product-image{
        height: auto;
        width: 600px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .product-detail{
        display: grid;
        grid-template-columns: 500px calc(100% - 520px);
        grid-gap: 10px;
        position: absolute;
        height: 100%;
        width: 100%;
        margin-top: -90px;
    }

    .product-info{
        height: 100%;
        width: 100%;
        padding: 100px;
        overflow-y: auto;
    }

    .product-image{
        height: auto;
        width: 100%;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .product-detail{
        grid-template-columns: 600px calc(100% - 620px);
        margin-top: -100px;
    }

    .product-info{
        height: 100%;
        width: 100%;
        padding: 150px 100px;
        overflow-y: auto;
    }

    .product-image{
        height: auto;
        width: 100%;
    }

}