/* Mobile */

.contact-seller-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 0 20px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .contact-seller-button{
        width: 300px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .contact-seller-button{
        width: 300px;
    }


}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .contact-seller-button{
        width: 400px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .contact-seller-button{
        width: 300px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .contact-seller-button{
        width: 400px;
    }

}