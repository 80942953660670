/* Loader Container */
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

/* Abstract Container */
.abstract-container {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Rotating Ring (Circular Time Concept) */
.rotating-ring {
    width: 100%;
    height: 100%;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    position: absolute;
    animation: rotate-ring 3s linear infinite;
}

/* Expanding Lines (Flow of Time) */
.expanding-lines {
    width: 100%;
    height: 100%;
    position: absolute;
    border-left: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
    animation: expand-lines 1.5s ease-in-out infinite;
}

/* Rotating Animation for the Ring */
@keyframes rotate-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Expanding and Contracting Lines Animation */
@keyframes expand-lines {
    0%, 100% {
        transform: scaleY(0.5);
    }
    50% {
        transform: scaleY(1);
    }
}

/* Loader Text */
.loader-text {
    margin-top: 20px;
}

/* Responsive Adjustments */
@media screen and (min-width: 640px) {
    .abstract-container {
        width: 80px;
        height: 80px;
    }
}

@media screen and (min-width: 768px) {
    .abstract-container {
        width: 80px;
        height: 80px;
    }
}
