/* No Data Container */
.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh; /* Adjust for visual balance */
    text-align: center;
}

/* Sad Icon Styling with Animation */
.success-icon {
    color: #366726;
    height: 75px;
    width: 75px;
}

/* Text Styling */
.success-text {
    color: #366726;
}

/* Responsive Adjustments */
@media screen and (min-width: 640px) {

    .success-icon {
        height: 75px;
        width: 75px;
    }

}

@media screen and (min-width: 768px) {
    .success-icon {
        height: 75px;
        width: 75px;
    }
}
