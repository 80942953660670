/* Mobile */

.social{
    text-align: center;
    margin-top: 40px;
}

.social p{
    text-align: center;
}

.social-links{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}

.social-link{
    height: 36px;
    width: 36px;
}

/* Small Tablets */
@media screen and (min-width: 640px){

    .social{
        margin-top: 50px;
    }

    .social-link{
        height: 42px;
        width: 42px;
    }

}

/* Medium Tablets */
@media screen and (min-width: 768px){

    .social{
        margin-top: 50px;
    }

    .social-link{
        height: 42px;
        width: 42px;
    }

}

/* Large Tablets */
@media screen and (min-width: 1024px){

    .social{
        margin-top: 50px;
    }

    .social-link{
        height: 46px;
        width: 46px;
    }

}

/* Laptops */
@media screen and (min-width: 1280px){

    .social{
        margin-top: 50px;
        padding-left: 200px;
        padding-right: 200px;
        padding-bottom: 50px;
    }

    .social-link{
        height: 36px;
        width: 36px;
    }

}

/* Large Screens */
@media screen and (min-width: 1536px){

    .social{
        margin-top: 100px;
        padding-left: 300px;
        padding-right: 300px;
    }

    .social-link{
        height: 40px;
        width: 40px;
    }

}